import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import cx from 'classnames';

import { ReactComponent as Placeholder } from './assets/placeholder.svg';

import * as cs from './AuthorCard.module.css';

import type { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  about?: string;
  name: string;
  avatar?: IGatsbyImageData | null;
  withPlaceholder?: boolean;
  largeAbout?: boolean;
}

export const AuthorCard = ({
  about,
  avatar,
  name,
  withPlaceholder,
  largeAbout,
}: Props) => {
  const imageData = avatar ? getImage(avatar) : null;

  return (
    <figure
      className={cs.root}
      itemProp="author"
      itemScope
      itemType="http://schema.org/Person"
    >
      {(withPlaceholder || imageData) && (
        <div className={cs.avatar}>
          {imageData && (
            <GatsbyImage
              className={cs.image}
              image={imageData}
              alt={name}
              itemProp="image"
              loading="lazy"
            />
          )}
          {withPlaceholder && !imageData && (
            <Placeholder className={cs.placeholder} />
          )}
        </div>
      )}

      <figcaption className={cs.caption}>
        <p className={cs.name} itemProp="name">
          {name}
        </p>
        {Boolean(about) && (
          <p
            className={cx(cs.about, { [cs.largeAbout]: largeAbout })}
            itemProp="description"
          >
            {about}
          </p>
        )}
      </figcaption>
    </figure>
  );
};
