import { useStaticQuery, graphql } from 'gatsby';

export function useSiteMetadata() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
          title
          author
          description
          siteUrl
          twitterUsername
          image
          twitter
          facebook
          instagram
          linkedin
          github
          dribbble
          youtube
          mastodon
          wellfound
          japanese
          foundingDate
          logo
          contactFormUrl
          subscribeFormUrl
          email
          calCom
          consultationCalCom
          dpl {
            socialImage
          }
        }
      }
    }
  `);

  return site.siteMetadata;
}
