import * as React from 'react';
import cx from 'classnames';

import * as styles from './WrapList.module.css';

interface Props<T> extends React.HTMLAttributes<HTMLUListElement> {
  entries: T[];
  renderItem: (entry: T) => React.ReactNode;
  getKey: (entry: T) => string;
  columnGap: number;
  rowGap: number;
  className?: string;
  innerRef?: React.Ref<HTMLUListElement>;
}

export const WrapList = <T,>({
  entries,
  renderItem,
  getKey,
  columnGap,
  rowGap,
  className,
  innerRef,
  ...rest
}: Props<T>) => (
  <ul
    className={cx(styles.root, className)}
    style={
      { '--row-gap': rowGap, '--column-gap': columnGap } as React.CSSProperties
    }
    ref={innerRef}
    {...rest}
  >
    {entries.map((entry) => (
      <li className={styles.item} key={getKey(entry)}>
        {renderItem(entry)}
      </li>
    ))}
  </ul>
);
