import * as React from 'react';

import { TagList } from '../TagList/TagList';
import { EntityCard, type EntityProps } from '../EntityCard/EntityCard';
import { getBlogPostTags } from '../../utils/blogPost';
import { routes } from '../../config/routes';

interface Props extends EntityProps {
  categories?: CustomTypes.FilterTag[];
  skills?: CustomTypes.FilterTag[];
  services?: CustomTypes.FilterTag[];
  withEntityType?: boolean;
  details?: React.ReactNode;
  date?: string;
  dateISO?: string;
  coverImage?: Queries.BlogPostPageEntryFragment['coverImage'];
  coverVideo?: Queries.BlogPostPageEntryFragment['coverVideo'];
  coverVideoLoop?: boolean;
  bgImage?: Queries.BlogPostPageEntryFragment['bgImage'];
  bgColorOverflow?: boolean | null;
  randomColorIndex?: number;
  logo?: {
    publicURL: string;
  };
}

export const BlogPostCard = ({
  slug,
  categories,
  skills,
  services,
  withEntityType,
  ...restProps
}: Props) => {
  const details = restProps.details ? null : (
    <TagList
      mainVariant={withEntityType ? 'EntityType' : 'Category'}
      entries={getBlogPostTags({
        categories,
        skills,
        services,
        withEntityType,
      })}
    />
  );

  return (
    <EntityCard
      type="post"
      slug={slug}
      url={routes.blogPost(slug)}
      details={details}
      {...restProps}
    />
  );
};
