import * as React from 'react';
import cx from 'classnames';

import * as icons14 from './assets/icons14';
import * as icons20 from './assets/icons20';
import * as icons24 from './assets/icons24';
import * as icons56 from './assets/icons56';

import * as cs from './Icon.module.css';

interface Props {
  name: string;
  size: 14 | 20 | 24 | 56;
  className?: string;
}

export const ICONS = {
  icons14: icons14,
  icons20: icons20,
  icons24: icons24,
  icons56: icons56,
};

export const Icon = ({ name, size = 24, className, ...restProps }: Props) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const IconSvg = ICONS[`icons${size}`][name];

  if (!IconSvg) {
    throw new Error(`Cannot find icon "${name}" in "${size}" size`);
  }

  return (
    <IconSvg
      className={cx(cs[`icon${size}`], className, 'ariaHidden')}
      aria-hidden
      {...restProps}
    />
  );
};
