// extracted by mini-css-extract-plugin
export var block = "ConsultationCtaMini-module--block--fc64b";
export var button = "ConsultationCtaMini-module--button--27b68";
export var description = "ConsultationCtaMini-module--description--7ba45";
export var image = "ConsultationCtaMini-module--image--75f38";
export var imageContainer = "ConsultationCtaMini-module--imageContainer--a5aa7";
export var indicator = "ConsultationCtaMini-module--indicator--0b1ce";
export var indicatorBG = "ConsultationCtaMini-module--indicatorBG--d8eac";
export var indicatorContainer = "ConsultationCtaMini-module--indicatorContainer--ec9a7";
export var iraBlock = "ConsultationCtaMini-module--iraBlock--85ba8";
export var iraButton = "ConsultationCtaMini-module--iraButton--fb9ef";
export var iraDescription = "ConsultationCtaMini-module--iraDescription--ab4a7";
export var iraIndicatorContainer = "ConsultationCtaMini-module--iraIndicatorContainer--63c6b";
export var ira_chatBlock = "ConsultationCtaMini-module--ira_chatBlock--b4c7a";
export var ira_chatButton = "ConsultationCtaMini-module--ira_chatButton--df21c";
export var ira_chatDescription = "ConsultationCtaMini-module--ira_chatDescription--459df";
export var ira_chatImageContainer = "ConsultationCtaMini-module--ira_chatImageContainer--d786b";
export var ira_chatIndicatorContainer = "ConsultationCtaMini-module--ira_chatIndicatorContainer--77546";
export var pulse = "ConsultationCtaMini-module--pulse--5e2f5";
export var selected = "ConsultationCtaMini-module--selected--fdae6";
export var title = "ConsultationCtaMini-module--title--94ec5";
export var wideWrapper = "ConsultationCtaMini-module--wideWrapper--9d93d";
export var wrapper = "ConsultationCtaMini-module--wrapper--8f1eb";